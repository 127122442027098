import React, { Component } from "react";
import { Modal, Form, Input, Row, Col, notification } from "antd";
import { editProduct, createProduct } from "../services/Api";
const FormItem = Form.Item;

export default class ProductFormModal extends Component {

    

    constructor(props) {
        super(props);

        this.submitForm = this.submitForm.bind(this);
        this.setField = this.setField.bind(this);
        this.fillForm = this.fillForm.bind(this);
        this.clearForm = this.clearForm.bind(this);

        this.formRef = React.createRef();
    }

    submitForm = e => {
        const { data, editing, setVisible, reloadData, reloadToLastPage } = this.props;
        e.preventDefault();

        this.formRef.current.validateFields().then(values => {

            if (values.cautionEx === undefined || values.cautionEx === null){
                values.cautionEx = ""
            }

            values.caution_ex = values.cautionEx
            values.manufactured_at = values.manufacturedAt
            values.how_to_uses = values.usage

            if (editing) {
                editProduct(data.id, values).then(response => {

                    if (response.status === 200){
                        notification["success"]({
                            message: "สำเร็จ!",
                            description:  `สร้างรายการใหม่เรียบร้อย`
                        });
                        setVisible(false);
                        reloadData();
                    }
                    
                });
            } else {
                // Transform object to match edit API
                createProduct(values).then(response => {
                    if (response.status === 200){
                        notification["success"]({
                            message: "สำเร็จ!",
                            description:  `สร้างรายการใหม่เรียบร้อย`
                        });
                        setVisible(false);
                        reloadToLastPage();
                    }
                });

            }


        }).catch(errorInfo => {
            notification["warning"]({
                message: "คำเตือน!",
                description:  `กรุณากรอกข้อมูลให้ครบ`
            });
        });
    };

    setField(key, val) {
        this.formRef.current.setFieldsValue({ [key]: val });
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    fillForm() {
        const { data } = this.props;
        this.setField("name", data.name);
        this.setField("code", data.code);
        this.setField("size", data.size);
        this.setField("usage", data.how_to_uses);
        this.setField("instruction", data.instruction);
        this.setField("caution", data.caution);
        this.setField("cautionEx", data.caution_ex);
        this.setField("contain", data.contain);
        this.setField("manufacturedAt", data.manufactured_at);
        this.setField("barcode", data.barcode);
    }

    clearForm() {
        this.formRef.current.resetFields();
    }

    render() {
        const { editing, visible, setVisible } = this.props;
        return (
        <Modal
            visible={visible}
            onCancel={()=>{setVisible(false)}}
            onOk={this.submitForm}
            width={400}
            title={editing ? "แก้ไขรายการ" : "เพิ่มรายการ"}
            okText={editing ? "แก้ไข" : "เพิ่ม"}
            afterClose={this.clearForm}
            cancelText={"ปิด"}
        >
            <Form ref={this.formRef}>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"ชื่อสินค้า"} name="name" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่ชื่อสินค้า"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"รหัส"} name="code" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่รหัส"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"ขนาดสินค้า"} name="size" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่ขนาดสินค้า"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"วิธีใช้"} name="usage" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่วิธีใช้"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"ข้อแนะนำ"} name="instruction" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่ข้อแนะนำ"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"คำเตือน"} name="caution" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่คำเตือน"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"คำเตื่อน(เพิ่มเติม)"} name="cautionEx" rules={[]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"บรรจุ"} name="contain" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่ข้อมูลบรรจุ"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"วันที่ผลิต"} name="manufacturedAt" rules={[
                                {
                                    required: true,
                                    message: "กรุณาใส่ข้อมูลบรรจุ"
                                }
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <FormItem label={"บาร์โค้ด"} name="barcode" rules={
                                [{
                                    required: true,
                                    message: "กรุณาใส่ข้อมูลบรรจุ"
                                }]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
        );
    }
}
