import React, { Component } from 'react';
import { getProducts, downloadLabel, deleteProduct } from "../services/Api";
import { Table, Input, Row, Col, Button, notification, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, BarcodeOutlined, PlusOutlined } from '@ant-design/icons';
import ProductFormModal from './ProductFormModal'

const { Search } = Input;
const confirm = Modal.confirm;


export default class ProductTable extends Component {
    
    
    constructor(props){
        super(props);
        this.state = {
          data: [],
          page: 1,
          pageSize: 5,
          total: 10,
          q: "",
          dataLoading: false,
          selectedData: {},
          formModalVislble: false,
          modalEditing: false
        }
        this.getData = this.getData.bind(this);
        this.reloadToLastPage = this.reloadToLastPage.bind(this)
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onCreateClick = this.onCreateClick.bind(this);
        this.onRowEditClick = this.onRowEditClick.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
      }

    onPageChange(page, pageSize){
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size){
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onSearch(value){
        this.setState({
            q: value,
        }, () => {
            this.getData()
        })
    }

    onCreateClick(){
        this.setState({
            formModalVislble: true,
            modalEditing: false
        });
    }

    onRowEditClick(row){
        const fillForm = this.formModal.fillForm
        this.setState(
            {
              formModalVislble: true,
              selectedData: row,
              modalEditing: true
            },
            () => {
                setTimeout(function(){ fillForm(); }, 100);
            }
          );
    }

    setFormModalVisible(val){
        this.setState({
            formModalVislble: val
        })
    }

    async getData(){
        this.setState({
            dataLoading: true
        }, async () => {
            const {q, page, pageSize} = this.state
            let result = await getProducts(q, page, pageSize)
            this.setState({
                data: result.data.results,
                total: result.data.count,
                total_page: result.data.total_page,
                dataLoading: false
            })
        })
    }
    
    componentWillMount(){
        this.getData()
    }

    showDeleteConfirm(row, lastRow, onPageChange, page, pageSize) {
        confirm({
            title: `ต้องการลบ ${row.name} (${row.code}) หรือไม่?`,
            async onOk() {
                let response = await deleteProduct(row.id);
                if (response.status === 200){
                    notification["success"]({
                        message: "สำเร็จ!",
                        description:  `ลบ ${row.name} (${row.code}) เรียบร้อย`
                    });
                }

                if (lastRow && page > 1){
                    page -= 1
                }
                await onPageChange(page, pageSize); 
        },
        onCancel() {}
        
        });
    }

    async reloadToLastPage(){
        const { total_page, pageSize, data, page } = this.state
        let next_total = total_page
        if (data.length === pageSize && page === total_page){
            next_total += 1
        }

        this.setState({
            page: next_total
        }, () => {
            this.getData()
        })
    }


    downloadProductLabel(row){
        downloadLabel(row.id, row.name, row.code)
    }

    render(){
        const { data, total, page, pageSize, modalEditing, formModalVislble, selectedData } = this.state

        let tableColumns = [
            {
        
                title: 'ชื่อสินค้า',
                dataIndex: 'name',
                key: 'name'
            },
            {
        
                title: 'รหัส',
                dataIndex: 'code',
                key: 'code'
            },
            {
                title: 'บรรจุ',
                dataIndex: 'contain',
                key: 'contain'
            },
            {
                title: 'ตัวเลือก',
                dataIndex: 'action',
                key: 'action',
                render: (text, row) => (
                    <div>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          icon={<BarcodeOutlined />}
                          onClick={() => {this.downloadProductLabel(row)}}
                          style={{ marginRight: 5 }}
                        />
                        <Button
                          type="default"
                          shape="circle"
                          size="small"
                          icon={<EditOutlined />}
                          onClick={() => this.onRowEditClick(row)}
                          style={{ marginRight: 5 }}
                        />
                        <Button
                          type="danger"
                          shape="circle"
                          size="small"
                          icon={<DeleteOutlined />}
                          onClick={() => this.showDeleteConfirm(row, data.length === 1, this.onPageChange, page, pageSize)}
                        />
                    </div>
                  )
            }
            
        ]

        return (
            <div>
                <Row>
                    <Col span={8}>
                        
                    </Col>
                    <Col span={10}>
                        <Search
                            placeholder="ค้นหาด้วยชื่อ หรือรหัส"
                            onSearch={this.onSearch}
                        />
                    </Col>
                    <Col span={5}>
                    </Col>
                    <Col span={1}>
                        <Button
                            style={{float: 'right'}}
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => this.onCreateClick()}
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col span={24}>
                        <Table 
                            rowKey={'id'} 
                            columns={tableColumns} 
                            dataSource={data} 
                            bordered 
                            locale={{filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล'}}
                            pagination={{current: page, pageSize: pageSize, total: total, onChange: this.onPageChange, onShowSizeChange: this.onPageSizeChange, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '50']}} />
                    </Col>
                </Row>
                <ProductFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadData={this.getData}
                    reloadToLastPage={this.reloadToLastPage}
                    editing={modalEditing}
                    data={selectedData}
                    onRef={ref => (this.formModal = ref)}
                    />
            </div>
        );
    }
}