import React, {Component} from 'react';
import ProductTable from './components/ProductTable';
import axios from 'axios'
import { notification } from 'antd';
import './App.css';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      errorToasterVisible: false,
      errorToasterTitle: "",
      errorToasterMessage: ""
    }
  }

  componentWillMount(){

    axios.interceptors.request.use(function (config) {
      // spinning start to show
      return config
     }, function (error) {
       return Promise.reject(error);
     });
 
     axios.interceptors.response.use(function (response) {
      // spinning hide
      return response;
    }, function (error) {

      notification["error"]({
          message: "เจ๊ง!",
          description:  `เกิดข้อผิดพลาดขณะทำรายการ กรุณาติดต่อผู้ดูแลระบบ`
      });

      return Promise.resolve(error.response); // dont' throw here!!! each function need to handle 500 individually
    });
  }

  render() {
    return (
      <div>
        <div className="app-container">
          <ProductTable/>
        </div>,
        

    </div>
    );
  }
}

export default App;