import axios from "axios";

const BACKEND_URL = 'https://ttb-server.kenganan17.com';

export const getProducts = async (q, page, limit) => {
    const res = await axios.get(BACKEND_URL+'/products', {
        params: {
            page, limit, q
        }
    });
    return res.data;
}

export const createProduct = async (product) => {
    const res = await axios.post(BACKEND_URL + `/products`, product);
    return res;
}

export const editProduct = async (id, product) => {
    const res = await axios.put(BACKEND_URL + `/products/${id}`, product);
    return res;
}

export const deleteProduct = async (id) => {
    const res = await axios.delete(BACKEND_URL + `/products/${id}`);
    return res;
}

export const downloadLabel = async (id, name, code) => {
    const res = await axios.get(
        BACKEND_URL+`/products/${id}/label`,
        {
            responseType: 'arraybuffer'
        }
    );
    
    let blob = new Blob([res.data], { type: 'image/png' }),
    blobURL = window.URL.createObjectURL(blob)

    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', `${name}(${code}).png`);

    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

